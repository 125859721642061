import React, { useState } from 'react';
import './FAQ.css';

const FAQ = ({ title, questions }) => {
    const [openIndex, setOpenIndex] = useState(null);

    const togglePanel = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className="faq-section">
            {title && (
                <div className="section-header">
                    <h2>{title}</h2>
                </div>
            )}

            <div className="faq-grid">
                {questions.map((item, index) => (
                    <div className="faq-item" key={index}>
                        <div className="faq-question" onClick={() => togglePanel(index)}>
                            <div className="question-content">
                                {item.icon && <i className={`fas ${item.icon}`}></i>}
                                <span>{item.question}</span>
                            </div>
                            <i className={`fas ${openIndex === index ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                        </div>
                        {openIndex === index && (
                            <div className="faq-answer">
                                {item.answer}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FAQ; 