import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Navbar from './Navbar';
import Footer from './Footer';
import './PurchaseConfirmation.css';

const PurchaseConfirmation = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/app');
        }, 5000);

        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <>
            <Helmet>
                {/* Event snippet for Website sale conversion page */}
                <script>
                    {`
                        console.log("PurchaseConfirmation");
                        gtag('event', 'conversion', {
                            'send_to': 'AW-1001697751/moJ4CKWZ_NICENfj0t0D',
                            'transaction_id': ''
                        });
                    `}
                </script>
            </Helmet>
            <Navbar />
            <div className="purchase-confirmation">
                <div className="confirmation-content">
                    <h1>Welcome to Premium! 🎉</h1>
                    <p>Congratulations! You now have access to all premium features.</p>
                    <p>Get ready to take your content to the next level!</p>
                    <p className="redirect-text">Redirecting to your premium dashboard in 5 seconds...</p>
                    <button 
                        className="button-plain"
                        onClick={() => navigate('/app')}
                    >
                        Start Using Premium Features Now
                    </button>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default PurchaseConfirmation; 