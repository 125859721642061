import React, { useState, useEffect } from 'react';
import Generator from './Generator';
import LanguageSelect from './LanguageSelect';
import api from '../../api';
import SavePromptDialog from './SavePromptDialog';

const CustomGenerator = ({ onOpenPricing }) => {
    const [customPrompts, setCustomPrompts] = useState([]);
    const [selectedPrompt, setSelectedPrompt] = useState(null);
    const [showSaveDialog, setShowSaveDialog] = useState(false);
    const [currentPromptText, setCurrentPromptText] = useState('');

    useEffect(() => {
        fetchCustomPrompts();
        // Initialiser avec des valeurs vides
        setSelectedPrompt(null);
        setCurrentPromptText('');
    }, []);

    const fetchCustomPrompts = async () => {
        try {
            const response = await api.get('/custom_prompts');
            setCustomPrompts(response.data.prompts);
        } catch (error) {
            console.error('Error fetching prompts:', error);
        }
    };

    const handlePromptSelect = (promptId) => {
        console.log("Prompt selected:", promptId);
        if (!promptId) {
            setSelectedPrompt(null);
            setCurrentPromptText('');
            return;
        }
        
        const prompt = customPrompts.find(p => p.id === parseInt(promptId));
        setSelectedPrompt(prompt);
        if (prompt) {
            setCurrentPromptText(prompt.prompt);
        }
    };

    const handlePromptTextChange = (text) => {
        setCurrentPromptText(text);
    };

    const handleSavePrompt = async (name) => {
        if (!currentPromptText.trim()) {
            return; // Ne pas sauvegarder si le prompt est vide
        }
        
        try {
            if (selectedPrompt) {
                await api.put(`/custom_prompts/${selectedPrompt.id}`, {
                    name,
                    prompt: currentPromptText
                });
            } else {
                await api.post('/custom_prompts', {
                    name,
                    prompt: currentPromptText
                });
            }
            await fetchCustomPrompts();
            setShowSaveDialog(false);
        } catch (error) {
            console.error('Error saving prompt:', error);
        }
    };

    const formFields = [
        {
            id: 'saved_prompts',
            type: 'select',
            label: 'Saved Prompts',
            options: [
                { value: '', label: '-- Create new prompt --' },
                ...customPrompts.map(p => ({ value: p.id, label: p.name }))
            ],
            onChange: (e) => handlePromptSelect(e.target.value),
            value: selectedPrompt?.id || ''
        },
        {
            id: 'custom_prompt',
            type: 'textarea',
            label: 'Custom Prompt',
            placeholder: 'Enter your custom prompt here...',
            rows: 8,
            value: currentPromptText,
            onChange: (e) => handlePromptTextChange(e.target.value),
            additionalButtons: currentPromptText ? [
                <button
                    key="save-prompt"
                    className="button-plain"
                    onClick={(e) => {
                        e.preventDefault();
                        setShowSaveDialog(true);
                    }}
                >
                    <i className="bi bi-save"></i> Save Prompt
                </button>
            ] : []
        },
        {
            id: 'model',
            type: 'select',
            label: 'AI Model',
            defaultValue: 'openai',
            options: [
                { value: 'openai', label: 'OpenAI (GPT-4)' }
            ]
        }
    ];

    return (
        <>
            <Generator
                title={<><span className="highlight">YT</span> to Custom</>}
                icon="bi bi-chat-left-quote-fill icon"
                formFields={formFields}
                generateEndpoint="/generate/custom_prompt"
                transformFormData={(data) => ({
                    url: data.url,
                    custom_prompt: data.custom_prompt,
                    model: data.model
                })}
                onOpenPricing={onOpenPricing}
                ignoredFields={['url', 'custom_prompt', 'saved_prompts']}
                currentPromptText={currentPromptText}
            />
            {showSaveDialog && (
                <SavePromptDialog
                    onClose={() => setShowSaveDialog(false)}
                    onSave={handleSavePrompt}
                    initialName={selectedPrompt?.name || ''}
                />
            )}
        </>
    );
};

export default CustomGenerator; 