import React, { useState, useContext } from 'react';
import Popup from '../UI/Popup'; // Assure-toi que ce composant Popup existe dans ton projet
import api from '../api'; // Pour envoyer les requêtes à l'API
import './PricingPopup.css'; // Fichier CSS spécifique pour ce composant
import LoadingButton from '../UI/LoadingButton';
import Toggle from '../UI/Toggle';
import { AuthContext } from '../Login/AuthContext';
import PricingPlans from '../Pricing/PricingPlans';

/* global gtag_report_conversion */
const PricingPopup = ({ onClose }) => {
    const { user } = useContext(AuthContext);
    const [selectedPlan, setSelectedPlan] = useState(process.env.REACT_APP_MONTH_PRICE_ID);
    const [isYearly, setIsYearly] = useState(false);
    const [checkoutLoading, setCheckoutLoading] = useState(false);

    const handleSubmit = async (id) => {
        if (!user) {
            window.location.href = '/login';
            return;
        }

        try {
            setCheckoutLoading(true);
            const endpoint = id === process.env.REACT_APP_LTD_PRICE_ID ? 'ltd_checkout' : 'checkout';
            const response = await api.post(`/${endpoint}/${id}`);
            gtag_report_conversion();
            window.location.href = response.data.checkout_url;
        } catch (error) {
            console.error('Error during checkout:', error);
        } finally {
            setCheckoutLoading(false);
        }
    };

    return (
        <div className="pricing-container">
            <Popup onClose={onClose}>
                <h2>Choose Your Plan</h2>
                <PricingPlans
                    isYearly={isYearly}
                    setIsYearly={setIsYearly}
                    selectedPlan={selectedPlan}
                    onPlanSelect={setSelectedPlan}
                    onSubmit={handleSubmit}
                    checkoutLoading={checkoutLoading}
                    user={user}
                />
            </Popup>
        </div>
    );
};

export default PricingPopup;
