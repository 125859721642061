import React, { useState, useContext } from 'react';
import Toggle from '../UI/Toggle';
import LoadingButton from '../UI/LoadingButton';
import api from '../api';
import './PricingPage.css';
import Navbar from './Navbar';
import Footer from './Footer';
import { AuthContext } from '../Login/AuthContext';
import PricingPlans from '../Pricing/PricingPlans';
import FAQ from '../UI/FAQ';

const PricingPage = () => {
    const { user } = useContext(AuthContext);
    const [isYearly, setIsYearly] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(process.env.REACT_APP_MONTH_PRICE_ID);
    const [checkoutLoading, setCheckoutLoading] = useState(false);

    const handleSubmit = async (id) => {
        if (!user) {
            window.location.href = '/login';
            return;
        }

        try {
            setCheckoutLoading(true);
            const endpoint = id === process.env.REACT_APP_LTD_PRICE_ID ? 'ltd_checkout' : 'checkout';
            const response = await api.post(`/${endpoint}/${id}`);
            window.location.href = response.data.checkout_url;
        } catch (error) {
            console.error('Error during checkout:', error);
        } finally {
            setCheckoutLoading(false);
        }
    };

    const pricingFaqQuestions = [
        {
            icon: 'fa-key',
            question: '[Lifetime Deal] Is it complicated to get an OpenAI account and API key?',
            answer: 'Not at all! Setting up an OpenAI account is a straightforward and free process. Once you\'ve registered, you\'ll have easy access to your API key. OpenAI\'s user-friendly interface makes it simple to get started, even if you\'re not tech-savvy.'
        },
        {
            icon: 'fa-dollar-sign',
            question: '[Lifetime Deal] How much will the OpenAI API cost me?',
            answer: 'The cost depends on the length of the video you\'re processing and the size of the generated content. It will be your responsibility to monitor your API usage, which you can easily do through your OpenAI dashboard at OpenAI Usage.'
        },
        {
            icon: 'fa-rotate-left',
            question: '[Lifetime Deal] Will you refund me if I don\'t like it?',
            answer: 'Absolutely, I will provide you with a no-questions-asked refund as long as your request is made within 30 days of the purchase.'
        }
    ];

    return (
        <>
            <Navbar />
            <div className="pricing-page">
                <div className="section">
                    <div className="section-header">
                        <h1>PRICING</h1>
                        <h2>Choose the plan that's right for you</h2>
                        <p>Transform your YouTube content into various formats with our AI-powered tools</p>
                    </div>

                    <PricingPlans
                        isYearly={isYearly}
                        setIsYearly={setIsYearly}
                        selectedPlan={selectedPlan}
                        onPlanSelect={setSelectedPlan}
                        onSubmit={handleSubmit}
                        checkoutLoading={checkoutLoading}
                        user={user}
                        className="pricing-page-plans"
                    />
                </div>

                <FAQ 
                    title={<>Frequently Asked <span className="highlight">Questions</span></>}
                    questions={pricingFaqQuestions} 
                />

            </div>
            <Footer />
        </>
    );
};

export default PricingPage; 